import React from "react";
import {
  Box,
  Switch,
  Tooltip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { COLORS } from "../../constants/colors";
import Info from "@mui/icons-material/Info";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const SidebarModuleCheck = ({
  label,
  isChecked,
  onChange,
  name,
  description = "",
  hideDivider = false,
}) => (
  <>
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: 1,
        ...(!hideDivider && { borderBottom: `1px solid ${COLORS.LIGHT_GRAY}` }),
      }}
    >
      {description && (
        <Box
          pr={1}
          xs={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            backgroundColor: COLORS.WHITE,
          }}
        >
          <Tooltip arrow title={description} placement="top-end">
            <Info color="secondary" />
          </Tooltip>
        </Box>
      )}

      <Box as="label" sx={{ whiteSpace: "nowrap", flex: 1, mr: 2 }}>
        {label}
      </Box>
      <Switch
        checked={isChecked}
        onChange={() => {
          onChange({
            target: {
              name,
              value: !isChecked,
            },
          });
        }}
        name={name}
      />
    </Box>
  </>
);

export const SidebarModule = ({ title, children, isExpanded = false }) => (
  <Accordion defaultExpanded={isExpanded}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography variant="h4" sx={{ fontWeight: 700 }}>
        {title}
      </Typography>
    </AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </Accordion>
);
