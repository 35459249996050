import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { makeApiRequest } from "../components/utils";

export const useBrandRelationshipsQuery = (brandId) => {
  return useQuery({
    queryKey: ["brandRelationships", { brandId }],
    queryFn: async () => {
      return makeApiRequest({
        endpoint: `/api/brands/${brandId}/brand_relationships`,
        method: "GET",
      });
    },
    keepPreviousData: true,
  });
};

export const useBrandRelationshipMutation = () => {
  const queryClient = useQueryClient();

  return {
    create: useMutation(
      async (data) => {
        return makeApiRequest({
          endpoint: "/api/brands/brand_relationships",
          method: "POST",
          body: JSON.stringify(data),
          additionalHeaders: {
            "Content-Type": "application/json",
          },
        });
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: "brandRelationships" });
        },
      }
    ),
    deleteRecord: useMutation(
      async (id) => {
        return makeApiRequest({
          endpoint: `/api/brands/brand_relationships/${id}`,
          method: "DELETE",
        });
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: "brandRelationships" });
        },
      }
    )
  };
};

export const useUpdateBrandRelationshipMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, data }) => {
      return makeApiRequest({
        endpoint: `/api/brands/brand_relationships/${id}`,
        method: "PUT",
        body: JSON.stringify(data),
        additionalHeaders: {
          "Content-Type": "application/json",
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: "brandRelationships" });
      },
    }
  );
};

export const useAvailableBrandRecommendationsQuery = (brandId) => {
  return useQuery({
    queryKey: ["availableBrandRecommendations", { brandId }],
    queryFn: async () => {
      return makeApiRequest({
        endpoint: `/api/brands/${brandId}/available_brands`,
        method: "GET",
      });
    },
    keepPreviousData: true,
  });
};
